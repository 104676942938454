import React from "react"
/** @jsx jsx */
import { jsx, Container, Heading, Flex, Box, Text } from "theme-ui"
// import RadialChart from "./RadialChart"
import { learningData, skillsData, workExperienceData } from "./cvData"
import Learning from "./Learning.js"
import SectionTitle from "./SectionTitle"
import SkillViz from "./SkillViz"
import WorkExperience from "./WorkExperience"
import { useAppContext } from "../../context/AppContext"

const title = {
  fontSize: ["32px", "48px"],
}
const interestTextContainer = {
  fontSize: 1,
}
const sectionDescription = {
  fontSize: 0,
  textAlign: "center",
}
const interestText = { fontSize: 0 }
const CurriculumVitae = props => {
  const appState = useAppContext()
  const isSmallScreen = !(appState.size.breakpointIdx > 0)
  return (
    <Box>
      <Container>
        <Flex className="flexColumn">
          <Heading as="h1" pt={[4]} className="alignSelfCenter" sx={title}>
            My Interactive CV
          </Heading>
          <Text className="alignSelfCenter italic" style={{ fontSize: "10px" }}>
            Last Updated 2020-07-01
          </Text>
        </Flex>

        <SectionTitle title={"Learning"} subTitle="I am a life-long learner" />
        <Text pt={2} pb={5} px={[3, 4]} sx={sectionDescription}>
          I see learning as something that happens every day. More notable and
          prolonged learning experiences have contributed to my knowledge about
          certain subjects and formed the way I learn. Below are some learning
          experiences in my life that stand out.
          <span className="bold">
            {` The area of the rectangle shows the relative importance of the
            experience. `}
          </span>
          Click on the square to see more.
        </Text>
        <Learning data={learningData} />
        <SectionTitle
          title={"Skills"}
          subTitle="I have a certain set of skills :)"
        />
        <Text pt={2} pb={5} px={[3, 4]} sx={sectionDescription}>
          I am continually learning new skills and honing the ones that help
          achieve my tasks. Below are a series of charts that show my expertise
          and application of each skill as well as how long I have been using
          it. The deeper I go into a skill, the more I realize that there is
          more to learn. The score I gave to each skill reflects how comfortable
          I am in using the skill/tool in a product or for a particular task.
          {isSmallScreen
            ? ` Click on each skill to see more.`
            : ` Hover over each skill to see a description and click
          for the text to remain.`}
        </Text>
        <Flex pb={5} id="skills" className={`flexColumn`}>
          <SkillViz
            elementId={"frontendSkills"}
            data={skillsData.frontend}
            skillName="Front End"
          />
          <SkillViz
            elementId={"developmentSkills"}
            data={skillsData.development}
            orderReverse={true}
            skillName="Development"
          />
          <SkillViz
            elementId={"backendSkills"}
            data={skillsData.backend}
            skillName="Back End"
          />
          <SkillViz
            elementId={"clientFacingSkills"}
            data={skillsData.clientFacing}
            orderReverse={true}
            skillName="Client-Facing"
          />
        </Flex>
        <SectionTitle
          title={"Work Experience"}
          subTitle={`“Don’t judge each day by the harvest you reap but by the seeds that you plant.”`}
        />
        <Text pt={2} pb={5} px={[3, 4]} sx={sectionDescription}>
          I have been fortunate to have had work experiences that have exposed
          me to many facets of a business from research, to designing production
          systems, to sales. I am a self-motivated problem solver but enjoy more
          working in a team. I firmly believe that if you want to go fast, go
          alone, if you want to go far, go with a team. I believe I contribute
          my unique perspective and expertise in order to create an environment
          conducive to success.
        </Text>
        <WorkExperience options={workExperienceData} />
        <SectionTitle
          title={"Other Interests"}
          subTitle={`My hobbies keep me balanced`}
        />
        <Flex className="flexColumn" sx={interestTextContainer}>
          <Text px={[3, 4]} pt={[3, 4]} sx={interestText}>
            I can get engrossed in technical work quite easily. I try to remain
            balanced by making time for the things that are important in my
            life. The two most important things I make time for are my loved
            ones and my physical and mental health. Burnout is real and costly.
            After that comes music. Playing musical instruments has been part of
            my life since I was a little kid, and I continue to practice and
            play in ensembles. Languages are another passion that I explore with
            some regularity. I speak English, Spanish, and German and continue
            to listen, read, and write as not to lose that creative impulse that
            comes from speaking a foreign language.
          </Text>
        </Flex>

        <Box py={3}></Box>
      </Container>
    </Box>
  )
}

export default CurriculumVitae
