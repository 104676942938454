import React, { useState, useEffect, useRef } from "react"
/** @jsx jsx */
import { jsx, Flex, Box, Text, Heading, useThemeUI } from "theme-ui"
import * as d3 from "d3"
import { motion } from "framer-motion"
import LearningContent from "./LearningContent"

const borderCornerHeight = 20
const borderCornerWidth = 1
const learningSectionHeight = 400
const learningSection = {
  position: "relative",
  height: `${learningSectionHeight}px`,
  overflow: "hidden",
}
const treemapRect = {
  position: "absolute",
}
const learningTitle = {
  position: "relative",
  top: "5px",
  left: "5px",
  fontSize: 0,
  fontWeight: "bold",
}
const learningContent = {
  position: "relative",
}
const borderCorners = {
  backgroundColor: "primary",
  width: `${borderCornerWidth}px`,
  height: `${borderCornerHeight}px`,
}

const getTreemapNodes = ({ data, width, height }) => {
  const treemap = data =>
    d3
      .treemap()
      .tile(d3.treemapBinary)
      .size([width, height])
      .padding(2)
      .round(true)(d3.hierarchy(data).sum(d => d.value))

  return treemap(data).leaves()
}
const BoundingBox = () => {
  return (
    <>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top: -borderCornerHeight / 2 + borderCornerWidth / 2,
          left: borderCornerHeight / 2,
          transform: `rotate(90deg)`,
        }}
      ></Box>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top: 0,
          left: 0,
          transform: `rotate(0deg)`,
        }}
      ></Box>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top: learningSectionHeight - borderCornerHeight,
          left: 0,
          transform: `rotate(0deg)`,
        }}
      ></Box>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top:
            learningSectionHeight -
            borderCornerHeight / 2 -
            borderCornerWidth / 2,
          left: borderCornerHeight / 2,
          transform: `rotate(90deg)`,
        }}
      ></Box>

      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top: -borderCornerHeight / 2 + borderCornerWidth / 2,
          right: 0 + borderCornerHeight / 2,
          transform: `rotate(-90deg)`,
        }}
      ></Box>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top: 0,
          right: borderCornerWidth / 2,
          transform: `rotate(0deg)`,
        }}
      ></Box>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top: learningSectionHeight - borderCornerHeight,
          right: borderCornerWidth / 2,
          transform: `rotate(0deg)`,
        }}
      ></Box>
      <Box
        className="positionAbsolute"
        sx={borderCorners}
        style={{
          top:
            learningSectionHeight -
            borderCornerHeight / 2 -
            borderCornerWidth / 2,
          right: borderCornerHeight / 2,
          transform: `rotate(90deg)`,
        }}
      ></Box>
    </>
  )
}

const Learning = props => {
  const { theme, colorMode } = useThemeUI()
  const [nodes, setNodes] = useState([])
  const [selectedNode, setSelectedNode] = useState("")
  const treemapRef = useRef(null)
  const containerSizeRef = useRef({ width: 0, height: 0 })
  const backgroundColor = theme.colors.background
  const rectBgColor = colorMode === "default" ? "#bfe5ff" : "#376382"
  // const textColor = colorMode === "default" ? "#1f1f1f" " "
  const handleOpenClick = event => {
    setSelectedNode(event.target.id)
  }
  const handleCloseClick = event => {
    console.log(`clicked closed`)
    setSelectedNode("")
  }
  useEffect(() => {
    if (treemapRef) {
      const width = treemapRef.current.offsetWidth
      const height = treemapRef.current.offsetHeight
      containerSizeRef.current = { width, height }
      setNodes(getTreemapNodes({ data: props.data, width, height }))
    }
  }, [])
  return (
    <Flex
      id="learning"
      className="justifyContentCenter"
      sx={learningSection}
      ref={treemapRef}
      pb={5}
    >
      {nodes.length > 0
        ? nodes.map((node, i) => {
            const isSelected = node.data.name === selectedNode
            const { width, height } = containerSizeRef.current
            return (
              <div key={`${node.data.name}-map`}>
                {isSelected | (selectedNode === "") ? (
                  <motion.div
                    // key={`${node.data.name}-map`}
                    id={node.data.name}
                    sx={treemapRect}
                    animate={{
                      top: isSelected ? 0 : node.y0,
                      left: isSelected ? 0 : node.x0,
                      width: isSelected ? width : node.x1 - node.x0,
                      height: isSelected ? height : node.y1 - node.y0,
                    }}
                    transition={{ duration: 0.3 }}
                    style={{
                      zIndex: isSelected ? 10 : 1,
                      cursor: isSelected ? "default" : "pointer",
                      backgroundColor: rectBgColor,
                    }}
                    onClick={
                      isSelected ? () => {} : event => handleOpenClick(event)
                    }
                  >
                    <Heading
                      sx={learningTitle}
                      id={node.data.name}
                      style={{ display: isSelected ? "none" : "block" }}
                      onClick={
                        isSelected ? () => {} : event => handleOpenClick(event)
                      }
                    >
                      {node.data.name}
                    </Heading>
                    <motion.div
                      key={`${node.data.name}-content`}
                      id={node.data.name}
                      sx={learningContent}
                      transition={{ delay: 0.2, duration: 0.2 }}
                      style={{
                        top: 0,
                        left: 0,
                        width: width,
                        height: height,
                        display: isSelected ? "block" : "none",
                        // border: `3px dotted ${rectBgColor}`,
                      }}
                      animate={{
                        opacity: isSelected ? 1 : 0,
                        backgroundColor: isSelected
                          ? backgroundColor
                          : rectBgColor,
                      }}
                    >
                      <BoundingBox width={containerSizeRef.current.width} />

                      <LearningContent
                        key={`${node.data.name}-learning-content`}
                        data={node.data}
                        handleCloseClick={handleCloseClick}
                      />
                    </motion.div>
                  </motion.div>
                ) : null}
              </div>
            )
          })
        : null}
    </Flex>
  )
}

Learning.defaultProps = {}

export default Learning
