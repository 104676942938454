import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
// CUSTOM COMPONENTS
import CurriculumVitae from "../components/CurriculumVitae"
import { PageContextProvider } from "../context/PageContext"
import "../styles/global.css"

const CV = () => {
  // const { aboutImg, assets } = data
  // const backgroundImages = assets.edges
  //   .map(a => ({ sort: Math.random(), value: a }))
  //   .sort((a, b) => a.sort - b.sort)
  //   .map(a => a.value)
  return (
    <PageContextProvider>
      <Layout>
        <CurriculumVitae />
      </Layout>
    </PageContextProvider>
  )
}
// export const query = graphql`
//   query {
//     aboutImg: file(relativePath: { eq: "dreds_aboutme.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     assets: allContentfulAsset(
//       filter: { title: { regex: "/^about-personal-background/" } }
//     ) {
//       edges {
//         node {
//           fluid {
//             ...GatsbyContentfulFluid_withWebp
//           }
//           title
//         }
//       }
//     }
//   }
// `

export default CV
