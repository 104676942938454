import React, { useEffect, useState, useRef } from "react"
/** @jsx jsx */
import { jsx, Box, Flex, useThemeUI } from "theme-ui"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import SliderControlOption from "./SliderControlOption"
import { motion } from "framer-motion"
import { useAppContext } from "../../../context/AppContext"

const arrowWidth = 40
const container = {
  height: `${arrowWidth}px`,
  overflow: "hidden",
}
const arrow = theme => ({
  height: "100%",
  flexShrink: 0,
  width: `${arrowWidth}px`,
  alignSelf: "center",
  position: "relative",
  zIndex: 10,
  backgroundColor: theme.colors.background,
})
// const sliderContainer = {
//   height: "100%",
// }
const optionsContainer = {
  display: "flex",
  position: "relative",
  left: "0px",
  height: "100%",
}
const getMaxOptionsShown = isSmallScreen => {
  return isSmallScreen ? 1 : 3
}

const SliderControls = props => {
  const { theme } = useThemeUI()
  const appState = useAppContext()
  const isSmallScreen = !(appState.size.breakpointIdx > 0)
  const maxOptionsShown = getMaxOptionsShown(isSmallScreen)
  const sliderControlRef = useRef(null)
  const [optionWidth, setOptionWidth] = useState(0)
  const [startSliderIndex, setStartSliderIndex] = useState(0)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)

  // Controls
  const arrowClick = addVal => {
    const newSliderIndex = startSliderIndex + addVal
    setStartSliderIndex(newSliderIndex)
    if (newSliderIndex === 0) {
      setShowLeftArrow(false)
    } else {
      setShowLeftArrow(true)
    }
    if (props.options.length - newSliderIndex === maxOptionsShown) {
      setShowRightArrow(false)
    } else {
      setShowRightArrow(true)
    }
  }
  useEffect(() => {
    if (sliderControlRef) {
      const containerWidth = sliderControlRef.current.offsetWidth
      setOptionWidth(
        Math.round((containerWidth - 2 * arrowWidth) / maxOptionsShown)
      )
    }
  }, [maxOptionsShown])
  return (
    <Flex mx={2} mb={3} sx={container} ref={sliderControlRef}>
      <motion.div
        sx={arrow(theme)}
        animate={{ opacity: showLeftArrow ? 1 : 0.2 }}
        style={{ cursor: showLeftArrow ? "pointer" : "default" }}
      >
        <AiOutlineArrowLeft
          className="h100 w100"
          onClick={showLeftArrow ? () => arrowClick(-1) : () => {}}
        />
      </motion.div>
      <Box>
        <motion.div
          sx={optionsContainer}
          animate={{ left: -1 * startSliderIndex * optionWidth }}
        >
          {props.options.map((option, i) => {
            return (
              <SliderControlOption
                key={option.organization}
                data={option}
                width={optionWidth}
                showDate={true}
                isSelected={props.selectedIndex === i}
                optionClickHandler={props.optionClickHandler}
              />
            )
          })}
        </motion.div>
      </Box>
      <motion.div
        sx={arrow(theme)}
        animate={{ opacity: showRightArrow ? 1 : 0.2 }}
        style={{ cursor: showRightArrow ? "pointer" : "default" }}
      >
        <AiOutlineArrowRight
          className="h100 w100"
          onClick={showRightArrow ? () => arrowClick(1) : () => {}}
        />
      </motion.div>
    </Flex>
  )
}
const defaultOptions = [
  {
    organization: "Org1",
    startDate: "Mar 2002",
    endDate: "Jun 2003",
    position: "position1",
  },
  {
    organization: "Org2",
    startDate: "Sept 2011",
    endDate: "Nov 2012",
    position: "position2",
  },
  {
    organization: "Org3",
    startDate: "March 2012",
    endDate: "June 2014",
    position: "position3",
  },
  {
    organization: "Org4",
    startDate: "March 2014",
    endDate: "June 2045",
    position: "position4",
  },
]

SliderControls.defaultProps = {
  // The number of options shown in the controls
  // maxOptionsShown: 3,
  selectedIndex: 0,
  options: defaultOptions,
}

export default SliderControls
