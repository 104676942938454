import React from "react"
/** @jsx jsx */
import { jsx, Flex, Heading, Text } from "theme-ui"
import { AiOutlineClose } from "react-icons/ai"
// import { useAppContext } from "../../../context/AppContext"

const closeIcon = {
  position: "absolute",
  top: ["5px", "10px"],
  left: ["5px", "10px"],
  width: "25px",
  height: "25px",
  cursor: "pointer",
}
const date = {
  fontSize: 0,
  fontStyle: "italic",
}
const description = { fontSize: "12px" }
const LearningContent = props => {
  return (
    <Flex px={4} py={3} className="flexColumn h100">
      <AiOutlineClose
        p={2}
        sx={closeIcon}
        onClick={() => props.handleCloseClick()}
      />
      <Heading as="h2" className="alignSelfCenter">
        {props.data.name}
      </Heading>
      <Text className="alignSelfCenter" sx={date}>
        {props.data.date}
      </Text>
      <Flex className="flexColumn overflowYAuto">
        {props.data.description.map((p, i) => {
          return (
            <Text key={i} pt={3} className="alignSelfCenter" sx={description}>
              {p}
            </Text>
          )
        })}
      </Flex>
    </Flex>
  )
}

LearningContent.defaultProps = {}

export default LearningContent
