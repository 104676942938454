import React, { useState, useEffect, useRef } from "react"
/** @jsx jsx */
import { jsx, useThemeUI, Box, Flex } from "theme-ui"
import { motion } from "framer-motion"
import CarouselSlide from "./CarouselSlide"

const carouselContainer = {
  height: "400px",
  width: "100%",
  overflow: "hidden",
  position: "relative",
}
const carousel = {
  display: "flex",
  height: "100%",
  position: "relative",
}

const SliderCarousel = props => {
  const carouselRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  useEffect(() => {
    if (carouselRef) {
      setContainerWidth(carouselRef.current.offsetWidth)
    }
  })
  return (
    <Box sx={carouselContainer} ref={carouselRef}>
      <motion.div
        sx={carousel}
        animate={{ left: -1 * props.selectedIndex * containerWidth }}
      >
        {props.options.map(option => {
          return (
            <CarouselSlide
              key={option.organization}
              width={containerWidth}
              data={option}
            />
          )
        })}
      </motion.div>
    </Box>
  )
}

SliderCarousel.defaultProps = {}

export default SliderCarousel
