import React, { useEffect, useRef } from "react"
/** @jsx jsx */
import { jsx, Box, Flex, Heading, useThemeUI } from "theme-ui"
import * as d3 from "d3"
import { useAppContext } from "../../../context/AppContext"

const containerLarge = orderReverse => ({
  flexDirection: orderReverse ? "row-reverse" : "row",
  height: "350px",
  marginTop: 3,
  marginBottom: 3,
})

const containerSmall = {
  flexDirection: "column",
  height: "100vh",
}
const title = {
  fontSize: 2,
}
const subTitle = {
  fontSize: "9px",
  textDecoration: "italic",
}
const contentPanelSize = isSmallScreen => ({
  width: isSmallScreen ? "100%" : "50%",
  height: isSmallScreen ? "50%" : "100%",
})

const descriptionTitle = {
  fontSize: [2, 3],
  letterSpacing: "1px",
  textTransform: "uppercase",
}
const descriptionText = {
  padding: ["32px", "48px"],
  fontSize: 0,
}

const margin = { top: 0, right: 0, bottom: 0, left: 0 }

const maxScore = 10

const getDescription = ({
  name,
  description,
  frequencyUse,
  firstUsed,
  lastUsed,
}) => {
  const html = `
          <div class="alignSelfCenter" style="font-size: 16px">
            ${name}
          </div>
          <div style="display: flex; justify-content: center; font-size: 9px; text-decoration: italic">
            <div
              class="flexRow"
              style= "padding-right: 8px",              
            >
              <div class="bold">Use:</div>
              <div class="italic" style="padding-left: 4px;">${frequencyUse}</div>
            </div>
            <div
              class="flexRow"
              style= "padding-right: 8px",              
            >
              <div class="bold">First Used:</div>
              <div class="italic" style="padding-left: 4px;">${firstUsed}</div>
            </div>
            <div class="flexRow">
              <div class="bold">Last Used:</div>
              <div class="italic" style="padding-left: 4px;">${lastUsed}</div>            
            </div>
          </div>
          <div class="flexColumn h100 overflowYAuto">${description
            .map(p => `<p>${p}</p>`)
            .join("")}
          </div>
                           
`
  return html
}

const drawRadialChart = ({
  elementId,
  data,
  dataVizColors,
  chartWidth,
  chartHeight,
  theme,
  colorMode,
  onMouseOver,
  onMouseOut,
  onMouseClick,
}) => {
  const groupColorMap = data.reduce((acc, cur) => {
    if (!acc.has(cur.group)) {
      acc.set(cur.group, { color: dataVizColors.shift(), name: cur.group })
    }
    return acc
  }, new Map())
  // scales and axes
  const width = chartHeight - margin.left - margin.right
  const height = chartHeight - margin.top - margin.bottom
  const radius = height / 2
  const radiusBounds = { inner: 0.5 * radius, outer: 0.95 * radius }
  const x = d3
    .scaleBand()
    .range([0, 2 * Math.PI])
    .align(0)
    .domain(data.map(d => d.name))
  const y = d3
    .scaleRadial()
    .range([radiusBounds.inner, radiusBounds.outer])
    .domain([0, maxScore])
  const xAxis = g =>
    g.attr("text-anchor", "middle").call(g =>
      g
        .attr("id", d => `${elementId}SkillsName`)
        .selectAll("g")
        .data(data)
        .join("g")
        .attr("data-id", d => d.id)
        .attr(
          "transform",
          d => `
          rotate(${((x(d.name) + x.bandwidth() / 2) * 180) / Math.PI - 90})
          translate(${radiusBounds.outer + 20},0)
        `
        )
        .call(g =>
          g
            .append("text")
            .attr("fill", theme.colors.text)
            .attr("transform", d =>
              (x(d.name) + x.bandwidth() / 2 + Math.PI / 2) % (2 * Math.PI) <
              Math.PI
                ? "rotate(90)translate(0,16)"
                : "rotate(-90)translate(0,-9)"
            )
            .style("font-size", "10px")
            .text(d => d.name)
        )
    )
  const yAxis = g =>
    g
      .attr("text-anchor", "middle")
      .call(g =>
        g
          .append("text")
          .attr("y", d => -y(y.ticks(5).pop()))
          .attr("dy", "-1em")
          .attr("font-size", 10)
          .attr("fill", theme.colors.text)
          .text("Score")
      )
      .call(g =>
        g
          .selectAll("g")
          .data(y.ticks(5).slice(1))
          .join("g")
          .attr("fill", "none")
          .call(g =>
            g
              .append("circle")
              .attr("stroke", theme.colors.text)
              .attr("stroke-width", 0.5)
              .attr("stroke-opacity", 0.2)
              .attr("r", y)
              .style("pointer-events", "none")
          )
          .call(g =>
            g
              .append("text")
              .attr("y", d => -y(d))
              .attr("dy", "0.35em")
              // .attr("stroke", theme.colors.text)
              // .attr("stroke-width", 2)
              .attr("font-size", 8)
              .style("font-weight", "bold")
              .text(y.tickFormat(2, "s"))
              .clone(true)
              .attr("fill", theme.colors.text)
              .attr("stroke", "none")
          )
      )

  const legendData = [...groupColorMap.values()]
  const legend = g =>
    g
      .append("g")
      .selectAll("g")
      .data(legendData)
      .join("g")
      .attr(
        "transform",
        (d, i) => `translate(-40,${(i - (legendData.length - 1) / 2) * 20})`
      )
      .call(g =>
        g
          .append("rect")
          .attr("width", 15)
          .attr("height", 15)
          .attr("fill", d => d.color)
      )
      .call(g =>
        g
          .append("text")
          .style("font-size", "9px")
          .attr("fill", theme.colors.text)
          .attr("x", 24)
          .attr("y", 8)
          .attr("dy", "0.35em")
          .text(d => d.name)
      )

  // Visual elements
  const arc = d3
    .arc()
    .innerRadius(d => y(0))
    .outerRadius(d => y(d.score))
    .startAngle(d => x(d.name))
    .endAngle(d => x(d.name) + x.bandwidth())
    .padAngle(0.1)
    .padRadius(radiusBounds.inner)
  // Remove previous graph
  d3.selectAll(`#${elementId} svg`).remove()
  const svg = d3
    .select(`#${elementId}`)
    .append("svg")
    .attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`)
    .style("width", width)
    .style("height", height)
    .style("overflow", "visible")

  // Add bars
  svg
    .append("g")
    .attr("id", `${elementId}barGroup`)
    .selectAll("path")
    .data(data)
    .join("path")
    .attr("fill", d => groupColorMap.get(d.group).color)
    .attr("data-id", d => d.id)
    .attr("d", arc)
    .style("cursor", "pointer")
    .on("mouseover", onMouseOver)
    .on("mouseout", onMouseOut)
    .on("click", onMouseClick)

  svg
    .append("g")
    .selectAll("path")
    .data(data)
    .join("path")
    .attr("stroke", theme.colors.text)
    .attr("stroke-width", 0.5)
    .attr("stroke-opacity", 0.2)
    .attr(
      "d",
      d =>
        `M${d3.pointRadial(x(d.name), radiusBounds.inner)} L${d3.pointRadial(
          x(d.name),
          radiusBounds.outer + 20
        )}`
    )

  svg.append("g").call(xAxis)

  svg.append("g").call(yAxis)
  svg.append("g").call(legend)
}

const SkillViz = props => {
  const appState = useAppContext()
  const isSmallScreen = !(appState.size.breakpointIdx > 0)
  const chartRef = useRef(null)
  const titleDiv = useRef(null)
  const hasClickedRef = useRef(false)
  const { colorMode, theme } = useThemeUI()
  const dataVizColors = [...theme.colors.dataVizColors]
  // Mouse events
  const onMouseOver = (event, d, i) => {
    const { name, description, frequencyUse, firstUsed, lastUsed } = d
    if (!hasClickedRef.current) {
      titleDiv.current = d3.select(`#${props.elementId}Description`).html()
      d3.select(`#${props.elementId}Description`).html(
        `${getDescription({
          name,
          description,
          frequencyUse,
          firstUsed,
          lastUsed,
        })}`
      )
    }

    d3.select(`#${props.elementId}barGroup`)
      .selectAll(`path:not(path[data-id=${d.id}])`)
      .style("opacity", 0.5)
    d3.select(`#${props.elementId}SkillsName`)
      .selectAll(`g:not(g[data-id=${d.id}])`)
      .style("opacity", 0.5)
  }
  const onMouseOut = (event, d, i) => {
    if (!hasClickedRef.current) {
      d3.select(`#${props.elementId}Description`).html(titleDiv.current)
      titleDiv.current = null
    }
    d3.select(`#${props.elementId}barGroup`)
      .selectAll("path")
      .style("opacity", 1)
    d3.select(`#${props.elementId}SkillsName`)
      .selectAll("g")
      .style("opacity", 1)
  }

  const onMouseClick = (event, d) => {
    const { name, description, frequencyUse, firstUsed, lastUsed } = d
    titleDiv.current = d3.select(`#${props.elementId}Description`).html()
    d3.select(`#${props.elementId}Description`).html(
      `${getDescription({
        name,
        description,
        frequencyUse,
        firstUsed,
        lastUsed,
      })}`
    )
    hasClickedRef.current = true
  }
  useEffect(() => {
    if (chartRef !== null) {
      drawRadialChart({
        chartWidth: chartRef.current.offsetWidth,
        chartHeight: chartRef.current.offsetHeight,
        elementId: props.elementId,
        data: props.data,
        dataVizColors,
        colorMode,
        theme,
        onMouseOver,
        onMouseOut,
        onMouseClick,
      })
    }
    return () => {}
  })
  return (
    <Flex
      // className={props.orderReverse ? "flexRowReverse" : ""}
      sx={
        appState.size.breakpointIdx > 0
          ? containerLarge(props.orderReverse)
          : containerSmall
      }
    >
      <Flex
        id={props.elementId}
        ref={chartRef}
        className={`positionRelative justifyContentCenter`}
        sx={contentPanelSize(isSmallScreen)}
      ></Flex>
      <Flex
        id={`${props.elementId}Description`}
        className={`flexColumn justifyContentFlexStart`}
        sx={{ ...contentPanelSize(isSmallScreen), ...descriptionText }}
      >
        <Flex
          className="h100 flexColumn justifyContentCenter"
          sx={descriptionTitle}
        >
          <Heading
            px={2}
            className="alignSelfCenter"
            style={{
              borderLeft: `2px solid ${theme.colors.primary}`,
              borderRight: `2px solid ${theme.colors.primary}`,
            }}
          >
            {props.skillName}
          </Heading>
        </Flex>
      </Flex>
    </Flex>
  )
}
SkillViz.defaultProps = {
  className: "",
  skillName: "Default Skill Name",
  elementId: "defaultRadialChartId",
  orderReverse: false,
  data: [
    { name: "Skill1", description: "This is my skill1", score: 7 },
    { name: "Skill2", description: "This is my skill2", score: 5 },
    { name: "Skill3", description: "This is my skill3", score: 6 },
  ],
}

export default SkillViz
