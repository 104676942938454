import React, { useState } from "react"
/** @jsx jsx */
import { jsx, useThemeUI, Text } from "theme-ui"
import { motion } from "framer-motion"

const title = theme => ({
  position: "relative",
  backgroundColor: theme.colors.background,
  top: "25%",
  fontSize: 1,
  zIndex: 2,
  height: "50%",
  pointerEvents: "none",
  display: "inline-block",
})
const dateRange = theme => ({
  position: "relative",
  top: "-25%",
  backgroundColor: theme.colors.background,
  zIndex: 1,
  height: "50%",
  pointerEvents: "none",
})
const option = {
  background: "none",
  border: "none",
  flexShrink: 0,
  justifyContent: "center",
  alignSelf: "center",
  height: "100%",
  cursor: "pointer",
}
const optionText = theme => ({
  color: theme.colors.text,
  fontSize: "13px",
})
const SliderControlOption = props => {
  const { theme } = useThemeUI()
  const [showDate, setShowDate] = useState(false)
  const { data } = props

  const mouseInteraction = type => {
    if ((type === "over") & !showDate) {
      setShowDate(true)
    }
    if ((type === "out") & showDate) {
      setShowDate(false)
    }
  }
  return (
    <button
      id={`${data.organization}`}
      key={data.organization}
      sx={{ ...option, width: props.width }}
      onMouseOver={() => mouseInteraction("over")}
      onMouseOut={() => mouseInteraction("out")}
      onClick={event => props.optionClickHandler(event)}
    >
      <motion.div
        className={`${props.className} alignSelfCenter`}
        sx={title(theme)}
        animate={{ top: showDate ? 0 : "25%" }}
        style={{
          borderBottom: props.isSelected
            ? `2px solid ${theme.colors.primary}`
            : "none",
        }}
      >
        <Text sx={optionText(theme)}>{data.organization}</Text>
      </motion.div>
      <motion.div
        sx={dateRange(theme)}
        animate={{ top: showDate ? "5%" : "-25%", opacity: showDate ? 1 : 0 }}
      >
        <Text
          sx={optionText(theme)}
          style={{ fontSize: "8px" }}
        >{`${data.startDate} - ${data.endDate}`}</Text>
      </motion.div>
    </button>
  )
}

SliderControlOption.defaultProps = {
  data: {
    organization: "Org1",
    startDate: "Mar 2002",
    endDate: "Jun 2003",
    position: "position1",
  },
  showDate: false,
}

export default SliderControlOption
