import React from "react"
/** @jsx jsx */
import { jsx, Flex, Text, Heading } from "theme-ui"
const title = {
  fontSize: 4,
}
const subTitle = {
  fontSize: "10px",
  fontStyle: "italic",
}

const SectionTitle = props => {
  return (
    <Flex className="flexColumn" pt={4}>
      <Heading as="h1" className="alignSelfCenter" sx={title} pt={2}>
        {props.title}
      </Heading>
      <Text className="alignSelfCenter textAlignCenter" sx={subTitle} pt={0}>
        {props.subTitle}
      </Text>
    </Flex>
  )
}

SectionTitle.defaultProps = {
  title: "Default Title",
  subTitle: "Default Sub Title",
}

export default SectionTitle
