import React from "react"
/** @jsx jsx */
import { jsx, Flex, Heading, Text } from "theme-ui"
import { useAppContext } from "../../../context/AppContext"
const subTitle = { fontSize: "10px", fontStyle: "italic" }

const description = {
  fontSize: "12px",
}
const img = {
  maxHeight: "250px",
  maxWidth: "250px",
}
const CarouselSlide = props => {
  const appState = useAppContext()
  const isSmallScreen = !(appState.size.breakpointIdx > 0)
  // console.log({ appState, isSmallScreen })
  return (
    <Flex className="h100" style={{ width: props.width, flexShrink: 0 }}>
      {isSmallScreen ? null : (
        <Flex p={4} className="w50 justifyContentCenter">
          <img
            className="w100 alignSelfCenter"
            sx={img}
            src={`${props.data.image}`}
          />
        </Flex>
      )}
      <Flex
        px={3}
        pt={4}
        className={`${
          isSmallScreen ? "w100" : "w50"
        } flexColumn justifyContentFlexStart overflowYAuto`}
      >
        <Heading as="h3" className="alignSelfCenter textAlignCenter">
          {props.data.organizationFullName}
        </Heading>
        <Text
          className="alignSelfCenter"
          sx={subTitle}
        >{`${props.data.startDate} - ${props.data.endDate}`}</Text>
        <Text mb={2} className="alignSelfCenter" sx={subTitle}>
          {props.data.position}
        </Text>
        {props.data.description.map((p, i) => {
          return (
            <Text
              key={`p${i}`}
              pt={2}
              className="alignSelfCenter"
              sx={description}
            >
              {p}
            </Text>
          )
        })}
      </Flex>
    </Flex>
  )
}

CarouselSlide.defaultProps = {
  width: "500px",
}

export default CarouselSlide
