import React, {
  createContext,
  useContext,
  // useEffect,
  // useState,
  // useRef,
} from "react"

const PageContext = createContext()
const usePageContext = () => {
  return useContext(PageContext)
}

const PageContextProvider = props => {
  // console.log({ props })
  return (
    <PageContext.Provider value={{ ...props.contextData }}>
      {props.children}
    </PageContext.Provider>
  )
}

// PageContextProvider.defaultProps = {}

export { PageContextProvider, usePageContext }
