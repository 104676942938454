import React, { useState } from "react"
/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import SliderCarousel from "./SliderCarousel"
import SliderControls from "./SliderControls"

const WorkExperience = props => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const optionClickHandler = event => {
    const elementId = event.target.id
    const clickedIndex = props.options.reduce((acc, cur, i) => {
      if (cur.organization === elementId) {
        acc.push(i)
      }
      return acc
    }, [])
    setSelectedIndex(clickedIndex[0])
  }
  return (
    <Flex className={`flexColumn`}>
      <SliderControls
        options={props.options}
        selectedIndex={selectedIndex}
        optionClickHandler={optionClickHandler}
      />
      <SliderCarousel options={props.options} selectedIndex={selectedIndex} />
    </Flex>
  )
}
const defaultOptions = [
  {
    organization: "Org1",
    startDate: "Mar 2002",
    endDate: "Jun 2003",
    position: "position1",
  },
  {
    organization: "Org2",
    startDate: "Sept 2011",
    endDate: "Nov 2012",
    position: "position2",
  },
  {
    organization: "Org3",
    startDate: "March 2012",
    endDate: "June 2014",
    position: "position3",
  },
  {
    organization: "Org4",
    startDate: "March 2014",
    endDate: "June 2045",
    position: "position4",
  },
]
WorkExperience.defaultProps = { options: defaultOptions }

export default WorkExperience
