import norwesternImg from "../../images/Northwestern_University_seal.svg.png"
import nasaImg from "../../images/NASA_Wormball_logo.svg"
import tnccImg from "../../images/Thomas_Nelson_logo.svg"
import metronImg from "../../images/metron_aviation_logo.jpg"
import cavanImg from "../../images/CavanSolutionsColorLogo.svg"
const learningData = {
  name: "top",
  children: [
    {
      name: "Art Summer School",
      date: "Summer 1991",
      value: 3,
      description: [
        `During the Summer after 4th grade, my parents enrolled me in the Art Summer School at my elementary school. Even though I enjoyed my previous summer camps, I remember being excited every morning to put images on paper. We learned about art history and how to "draw what we saw" on different mediums. My favorite was watercolor, even though I found it the most difficult. The field trips to art museums also stand out in my mind.`,
        `This summer left me with a tint of art that influenced all subsequent learning experiences. What is particularly nice about that is that I have been able to keep beauty and creativity in mind even though my learning path has been largely technical. I feel that gives more meaning to what I do.`,
      ],
    },
    {
      name: "USF Summer School",
      date: "Summer 1994",
      value: 10,
      description: [
        `In the Summer after 7th grade, my parents enrolled me in Summer School at the University of South Florida. It was soo cool to take classes on a college campus and walk around campus buildings like regular students. While I enjoyed all my classes that Summer, the highlight was the lunches at the Student Center where we would quickly eat our lunches and play ping pong for longer than we should have.`,
        `I took four classes that Summer: Astronomy, Algebra, Computer Programming, and Environmental Sciences. I was enamored with each subject. I first learned to do Math with symbols in Algebra. In Computer Programming, I learned that you could tell a computer what to do, but you had to be explicit. The main theme of my Environmental Sciences class was that the environment operated in cycles. My favorite was Astronomy. I felt like every day, I was told something about the universe that was jaw-dropping. When I learned that stars are born and die too, I studied every detail of the Hertzsprung–Russell diagram.`,
        `I remember this Summer School being quite challenging. The level of coursework, homework, and tests was something I had not experienced until that Summer. I had to work with other students on projects and complete homework assignments well into the night. This Summer was significant because I feel like I learned the discipline for work that I still have today. I pushed myself to do well that Summer, but it was also the most fun I had had.`,
      ],
    },
    {
      name: "Northwestern University",
      date: "Sep 2000 - Jun 2004",
      value: 30,
      description: [
        `After taking a detour through Electrical Engineering, I graduated with an Applied Mathematics and Engineering Sciences degree from Northwestern Univesity. I switched degrees to Applied Math because it brought many problems from various fields of Engineering under the Math umbrella. I was attracted to its interdisciplinary nature.`,
        `The Summer before freshman year, I attended Summer School for minority freshmen attending Northwestern where we learned Calculus I/II, Chemistry, and Linear Algebra. I was a bit freaked out about attending Northwestern and this Summer experience allayed those fears, somewhat. Building relationships with the people I would later do all-nighter problem sets was the best part of this experience.`,
        `Picking my favorite subjects feels a bit like picking a favorite child, but the most notable subjects were Numerical Solutions (to differential equations) and Fluid Dynamics. Seeing the patterns produced by the Navier-Stokes equation was a source of endless curiosity. Turning equations into code and writing approximation algorithms to solve those equations was like joining two water droplets. I was able to understand the symbols much better by understanding them more as instructions and indices as counters. I also took more Abstract Math classes like Real Analysis and Abstract Algebra. Writing proofs that accounted for all possible conditions was hard! Luckily, the skill of finding all possible conditions of a problem is readily used in many subjects, particularly coding.`,
        `Being at University was so much more than the academic education I received. Part of me wishes that I would have had more work experience and lived a little more before going to University, but the other part wouldn't change a thing about it. The great people I met and the guidance I recieved from professors are indelible.`,
      ],
    },
    {
      name: "ETH",
      date: "Sep 2002 - Jun 2003",
      value: 15,
      description: [
        `I studied abroad during my Junior Year at University in Zurich, Switzerland at the Eidgenössische Technische Hochschule (ETH). I didn't have the requisite language proficiency for studying at the ETH, since studying abroad was a last-minute decision, so I had to take intensive language courses in Germany prior to starting the Semester. That still didn't prepare me for my classes taught in German. Once I knew the Math vocabulary, it wasn't so bad. This probably wouldn't have been so easy in a Literature or History class.`,
        `During this year, I took more rigorous Math and Physics classes like Real Analysis, Projective Geometry, and Quantum Physics. The most mind-bending class was one that questioned what was the shape or our space. Through topological mind experiments and results from Experimental Physics, we discussed how we would know the shape of our universe. This is something I still ponder from time to time.`,
        `Going to a foreign country and taking classes in a non-native tongue was a wild ride! The classes were tough and, at times, overwhelming, but the environment was exhilarating.`,
      ],
    },
    {
      name: "NECSI",
      date: "August 2005",
      value: 5,
      description: [
        `My first job out of University was at NASA Langley Research Center, where I researched Air Traffic. I used agent-based models to investigate potential urban traffic patterns. This led me to study more Complex Systems and how to analyze and simulate them. I took a Summer course at the New England Complexity Science Institute in Cambridge, MA that was an introduction to the Complexity Sciences with a focus on emergence, self-organization, and cellular automata.`,
        `This Summer course brought together professionals with different backgrounds and was the first interdisciplinary environment I experienced. I loved, loved, loved being around Biologists, Ecologists, Physics, and Medical Doctors and hear the challenges of each field.`,
      ],
    },
    {
      name: "Santa Fe Institute",
      date: "June 2007",
      value: 15,
      description: [
        `I couldn't get enough of Complex Systems Science after NECSI, so I enrolled in the Santa Fe Institute Summer School. This was a longer 4-week course which went into more depth about each subject. The focus areas were Non-Linear Dynamics, Networks, and Agent-Based Modeling. The network analysis and pattern formation topics were the most fascinating as networks dominate our lives. We also had a final project in which I focused on using Time-Delay embedding to predict non-linear data.`,
        `Since this Summer School was longer, I got more of a chance to interact with the other students. There is something special about being around scientists from countries all over the world. Hearing about their research was captivating, but listening to their stories about their home country made Summer memorable.`,
        `The Summer School did not take place at the Santa Fe Institute, but we did visit the Institute that overlooks Santa Fe a handful of times for special lectures. We were also invited to stay for "Tea Time", an afternoon break where the researchers would gather and sometimes talk about research. It was interdisciplinary cross-pollination. I can't imagine the amount of fruit that has grown from those conversations.`,
      ],
    },
    {
      name: "MIT OCW",
      date: "Early 2000's",
      value: 3,
      description: [
        `I don't remember the date I first saw MIT's OpenCourseWare website, but do remember the feeling. I felt more disbelief than excitement like if you were to receive a gift of great value without expecting it. I have always admired MIT as an institution, and to be looking through the free course content from renowned professors seemed too good to be true. Once I realized that that's exactly what I was looking at, the excitement set in. I have taken several Math courses on OCW to help with what I was doing professionally at the time. But, most of the time, I just find the targeted material that I need.`,
        `I added this learning experience because it represented a shift in how I thought about learning. My generation has lived through the experience of having to work more for information and having it readily available. Having free course content from one of the most prestigious institutions available on-demand was a game-changer for me, but also everyone else. I knew it was a trend that would catch on, and it has. I am eager to see the long-term impacts of making this learning widely available.`,
      ],
    },
    {
      name: "Online Courses",
      date: "Throughout 2010's - Current",
      value: 19,
      description: [
        `Online content has become an integral part of my continuing education. I don't think I could have acquired my programming and data analysis knowledge without sites like YouTube, Udemy, FrontendMasters, and Coursera, to name a few. There are many good teachers out there showcasing their talent and we all benefit. Education is becoming a commodity that is accessible to anyone, and I love it. Teachers enrich lives, and I happy that in the information age technology enables people's passion for teaching and learning.`,
      ],
    },
  ],
}

const skillsData = {
  frontend: [
    {
      id: "react",
      name: "React",
      description: [
        `React is my go-to front-end framework. I use it in both my professional and personal projects. The most comprehensive application I have built is a multiple-app web data analysis and simulation platform for Air Traffic Data. It consumes data from various sources and visualizes the processed data in a customizable dashboard. The App integrates with a user-management system and renders components based on authorization`,
        `I also use React on my projects such as my personal website.`,
      ],
      score: 8,
      group: "Framework",
      frequencyUse: "Daily",
      lastUsed: "Current",
      firstUsed: "Mar 2018",
    },
    {
      id: "gatsby",
      name: "Gatsby",
      description: [
        `I learned Gatsby because I wanted to build a statically-rendered personal site. I also wanted the experience build a site using the Javascript, APIs, and Markup stack. I have learned to optimize web resources (e.g. images), to render Markup, theming, to use a Headless CMS, and to use serverless functions for any server-related action.`,
      ],
      score: 6,
      group: "Framework",
      frequencyUse: "Monthly",
      firstUsed: "Beginning 2019",
      lastUsed: "Current",
    },
    {
      id: "d3",
      name: "D3",
      description: [
        `I use D3 for creating custom data visualizations. D3 can be overkill for simple charts so I use Vega-Lite for easier charts. I usually integrate the charts into React components, but I am starting to use D3 more for data exploration with the Obesrvablehq.com notebooks. `,
        `Learning to bind data to visual components was illuminating for me. This led me to learn about the Grammar of Graphics which is the context I use when thinking about visualizing data.`,
      ],
      score: 6,
      group: "Data Visualization",
      frequencyUse: "Weekly",
      firstUsed: "Mid 2015",
      lastUsed: "Current",
    },
    {
      id: "Animation",
      name: "Animation",
      description: [
        `I am new to Web Animation. I was previously only using D3 and CSS keyframes for animations , but I have recently been learning to animate components using Framer Motion for HTML components and GreenSock for SVG elements. The need for web animations arose because I wanted to create a better user experience for the tools I was building.`,
      ],
      score: 3,
      group: "Animation",
      frequencyUse: "BiMonthly",
      firstUsed: "Aug 2020",
      lastUsed: "Current",
    },
    {
      id: "headlessCMS",
      name: "Headless CMS",
      description: [
        `I have built several JAM stack websites using a Headless Content Management System (CMS) including my personal website. I use Contentful CMS on a more consistent basis but have built and deployed a Strapi with a Mongo backend while experimenting with CMSs.`,
      ],
      score: 5,
      group: "CSS",
      frequencyUse: "Monthly",
      firstUsed: "Mar 2019",
      lastUsed: "Current",
    },
  ],
  development: [
    {
      id: "html",
      name: "HTML",
      description: [
        `I use appropriate HTML 5 elements for the structure of all web pages and applications.`,
      ],
      score: 9,
      group: "Frontend",
      frequencyUse: "Daily",
      firstUsed: "2008",
      lastUsed: "Current",
    },
    {
      id: "css",
      name: "CSS/SCSS",
      description: [
        `I use CSS written as SCSS to style web page elements and animations. I have created web UI themes using SCSS and CSS variables. I am adept at using external UI frameworks like Bootstrap, Bulma, theme-ui, and Material-UI.`,
      ],
      score: 8,
      group: "Frontend",
      frequencyUse: "Daily",
      firstUsed: "2008",
      lastUsed: "Current",
    },
    {
      id: "javascript",
      name: "Javascript",
      description: [
        `ECMAScript (ES) is my bread and butter for all things front end. I began learning and using ES fundamentals before the release of ES6 but started using it to build more complex applications afterward. I use Webpack for bundling, Prettier for formatting, ESLint for linting, Babel for transpilation, and Jest for testing. I usually write ES using the React framework.
        `,
      ],
      score: 8,
      group: "Frontend",
      frequencyUse: "Daily",
      firstUsed: "2011",
      lastUsed: "Current",
    },
    {
      id: "typescript",
      name: "Typescript",
      description: [
        "I have only recently begun to learn and refactor important applications into Typescript. While refactoring has been tedious, the code quality has improved. ",
      ],
      score: 2,
      group: "Frontend",
      frequencyUse: "Daily",
      firstUsed: "Oct 2020",
      lastUsed: "Current",
    },
    {
      id: "matlab",
      name: "MATLAB",
      description: [
        `I learned MATLAB at University in my Linear Alebgra courses and Numerical Computation courses. The fact that in MATLAB a matrix is a first-class citizen made it easy to apply it to computation. I continued to use MATLAB during the first part of my work experience doing things like solving optimization problems, creating algorithm prototypes, and tools with GUIs.`,
        `Python has largely taken MATLAB's place for analysis, but I still have a lot of legacy code that I use as a reference when writing computational methods.`,
      ],
      score: 9,
      group: "Backend/Analysis",
      frequencyUse: "Daily",
      firstUsed: "2002",
      lastUsed: "2016",
    },
    {
      id: "python",
      name: "Python",
      description: [
        `I began using Python for data analysis after transitioning from MATLAB. Since then, Python has been my go-to backend language. I have used Python Web Frameworks, database interfaces, APIs, web-scraping, data-pipelining, and job scheduling (e.g. Airflow). I use Jupyter Lab for any analysis or prototyping code.`,
      ],
      score: 9,
      group: "Backend/Analysis",
      frequencyUse: "Daily",
      firstUsed: "2012",
      lastUsed: "Current",
    },
    {
      id: "perl",
      name: "Perl",
      description: [
        "I used PERL early on in my career for parsing large text and datasets. I would write PERL scripts to parse and clean data. While I don’t use it on a daily basis today, I still support legacy code written in PERL.",
      ],
      score: 5,
      group: "Backend/Analysis",
      frequencyUse: "Quarterly",
      firstUsed: "2006",
      lastUsed: "Current",
    },
    {
      id: "bash",
      name: "Bash",
      description: [
        `For web applications and tasks for data pipelining, I use Linux servers. Bash is my preferred shell. When the task requires low-level OS commands, I'll write bash scripts.`,
      ],
      score: 7,
      group: "Backend/Analysis",
      frequencyUse: "BiMonthly",
      firstUsed: "2006",
      lastUsed: "Current",
    },
    {
      id: "cicd",
      name: "CI/CD",
      description: [
        `Along with Test-Driven Development, CI/CD pipelines are my principal workflows for production applications. I most use GCP Cloud Build for CI/CD, but have also used Bamboo, Bitbucket Pipelines, and Github Actions in the past.`,
      ],
      score: 5,
      group: "DevOps",
      frequencyUse: "Monthly",
      firstUsed: "2018",
      lastUsed: "Current",
    },
    {
      id: "containerization",
      name: "Containerization",
      description: [
        `I use containers for most development tasks. My preferred container runtime is Docker. For multi-container environments, I will use docker-compose and Kubernetes for larger ones that require more horizontal scalability.`,
      ],
      score: 8,
      group: "DevOps",
      frequencyUse: "Daily",
      firstUsed: "2018",
      lastUsed: "Current",
    },
  ],
  backend: [
    {
      id: "docker",
      name: "Docker",
      description: [
        `I learned containerization using Docker. I find it easy and pleasant to develop an application inside a container. I can create images, manage containers, configure container resources and container logging.`,
        `I continue using Docker for my OCI-compliant container runtime when needed for orchestration systems like Kubernetes.`,
      ],
      score: 8,
      group: "Infrastructure",
      frequencyUse: "Daily",
      firstUsed: "2018",
      lastUsed: "Current",
    },
    {
      id: "dockerCompose",
      name: "Docker Compose",
      description: [
        `When I begin to create a multi-container application, I use docker-compose for container management. I am able to set up services, networking, and storage for the application. I use version 3.6+ for configuration definition.`,
        `I have taken docker-compose multi-container applications to production, but now prefer Kubernetes for container orchestration.`,
      ],
      score: 8,
      group: "Infrastructure",
      frequencyUse: "Daily",
      firstUsed: "2018",
      lastUsed: "Current",
    },
    {
      id: "kubernetes",
      name: "Kubernetes",
      description: [
        `Kubernetes has become my preferred method for container orchestration. I am currently moving a large multi-container application to Kubernetes on both the local machine for development and GCP for production. I have created all the necessary manifests to migrate the components to pods and set up the networking using services. I am currently working on service discovery. I use microk8s for local development and GKE for production.`,
      ],
      score: 3,
      group: "Infrastructure",
      frequencyUse: "Daily",
      firstUsed: "Nov 2020",
      lastUsed: "Current",
    },
    {
      id: "terraform",
      name: "Terraform",
      description: [
        `I am using infrastructure as code using Terraform to create a large multi-container application on GCP. I have only started using Terraform recently when I had to recreate the system from scratch and saw the need to make the process easily repeatable and automatic.`,
      ],
      score: 1,
      group: "Infrastructure",
      frequencyUse: "Weekly",
      firstUsed: "Feb 2020",
      lastUsed: "Current",
    },
    {
      id: "oracle",
      name: "Oracle",
      description: [
        `I used and developed Aviation systems using Oracle Databases (DB) for about 10 years. The operational DB and Post-Operational Data Warehouse DBs were Oracle. I learned to write complex queries/SQL scripts, create DB Views, and use it as a backend for data APIs.`,
        `I have also contributed to the design and Data Model definition for a large-scale Post-Operation Data Warehouse. I used the ER/Studio tool to create the Data Model.`,
      ],
      score: 6,
      group: "Database",
      frequencyUse: "Weekly",
      firstUsed: "2008",
      lastUsed: "2018",
    },
    {
      id: "postgres",
      name: "Postgres",
      description: [
        `While working with Oracle databases at my job, I was using the open-source alternative, PostgresSQL, in my personal projects. Much of the same knowledge using Oracle databases applied.`,
        `When using Postgres in production I use a container if needed for a microservice in a larger application, or a Cloud-Provider managed relational DB service (e.g. GCP's Cloud SQL).`,
      ],
      score: 7,
      group: "Database",
      frequencyUse: "BiWeekly",
      firstUsed: "2016",
      lastUsed: "Current",
    },
    {
      id: "elasticsearch",
      name: "Elasticsearch",
      description: [
        `I implemented a cloud-based Elasticsearch cluster for the structured data storage of a data analysis and simulation platform. I configured end-to-end encryption for each node as well as used Elasticearch native identity management system. I have learned to optimize indices for faster reads and write asynchronous code for faster writes. I also am to manage the life-cycle of the indices to ensure data availability.`,
        `The other application of the Elasticstack that I use frequently is Kibana. I use it to visualize the data and spot anomalous or missing data.`,
      ],
      score: 8,
      group: "Database",
      frequencyUse: "Daily",
      firstUsed: "2017",
      lastUsed: "Current",
    },
    {
      id: "django",
      name: "Django",
      description: [
        `Django was the first web framework that I used. I started using it to create websites using templating, but then began to use the Rest Framework when I started using React. Learning all the Django settings has taught me how browsers interact with web servers as well as how to safeguard an application against attack.`,
        `Even though I have moved to using FastAPIs for APIs, I continue to use Django for its user management capabilities.`,
      ],
      score: 7,
      group: "Web Frameworks",
      frequencyUse: "Daily",
      firstUsed: "2018",
      lastUsed: "Current",
    },
    {
      id: "fastapi",
      name: "Fast API",
      description: [
        `Fast API is my go-to backend API framework. It's not as opinionated as Django and can handle asynchronous requests. I use Fast API for a large data analysis and simulation platform. It handles requests for managing the data (i.e. retrieval, update, deletion) as well as the creation of the simulation scenarios.`,
      ],
      score: 7,
      group: "Web Frameworks",
      frequencyUse: "Daily",
      firstUsed: "2019",
      lastUsed: "Current",
    },
    {
      id: "experssjs",
      name: "Express JS",
      description: [
        `I am only beginning to use Node for the backend web framework. I haven't yet developed with ExpressJS for a production system, but I have finished several online classes that used ExpressJS as the server framework. It would be nice to not have to context shift as much when coding frontend and backend applications, but I don't see using it too much in the near future.`,
      ],
      score: 1,
      group: "Web Frameworks",
      frequencyUse: "Daily",
      firstUsed: "Oct 2020",
      lastUsed: "Current",
    },
    {
      id: "dataPreparation",
      name: "Data Preparation",
      description: [
        `Much of my professional career has involved data preparation of some sort. In order to conduct analyses for a report or data integration into a system, I have had to ingest the data, clean it, format it, and transform it to fit the needs of the analysis. This has made me better at data exploration to extract relevant information from data sets.`,
      ],
      score: 9,
      group: "Analysis",
      frequencyUse: "Daily",
      firstUsed: "2004",
      lastUsed: "Current",
    },
    {
      id: "dataVisualization",
      name: "Data Visualization",
      description: [
        `As long as I have been working with data, I have been visualizing it. I like to think of it as trying to answer a question with a picture. The great thing about the web browser is that you can make those pictures interactive thereby involving the reader in your storytelling.`,
        `The tool I use for data visualization depends on the need. I usually use Python with Jupyter Notebooks for exploratory analysis. My preferred library for visualization is Altair since it aligns closely with Grammer of Graphics, but also use Bokeh and Matplotlib when needed. When using Javascript, I gravitate to PlotlyJS for simple Charts and D3 for more complex and custom ones.`,
      ],
      score: 9,
      group: "Analysis",
      frequencyUse: "Daily",
      firstUsed: "2004",
      lastUsed: "Current",
    },
    {
      id: "gcloud",
      name: "GCP",
      description: [
        `I have been using cloud resources for personal and professional projects for about 5 years. Initially I was deploying to cloud virtual machines on Digital Ocean and AWS, but then I switched entirely to GCP. The GCP technologies I use on a consitent basis are: IAM, Kubernetes, Compute Instances, Networking, App Engine, Cloud Functions, Cloud Storage, Big Query and Pub/Sub.`,
      ],
      score: 7,
      group: "Cloud",
      frequencyUse: "Daily",
      firstUsed: "2016",
      lastUsed: "Current",
    },
  ],
  clientFacing: [
    {
      id: "requirementsDefintion",
      name: "Requirements",
      description: [
        `I have spent much of my career developing, implementing, and supporting large-scale Aviation systems. Part of the Systems Engineer’s efforts is to develop requirements that would drive development that would satisfy contractual obligations. I used the JAMA requirements management tool to track requirements approval status and create test cases for each requirement.`,
        `As part of the public bidding process, I have experience in mapping public bid requirements to existing system functionality, thereby conducting a gap analysis and identifying new functionality needed to bridge that gap.`,
        `Writing good requirements is a skill that I use periodically when planning development cycles around user-specified needs.`,
      ],
      score: 4,
      group: "Contracts",
      frequencyUse: "Daily",
      firstUsed: "2010",
      lastUsed: "2018",
    },
    {
      id: "implementation",
      name: "Implementation",
      description: [
        `Ten years of my career were spent in a company that developed Air Traffic Flow Management Systems. At first, I supported the FAA in the USA, then we won public contracts in Colombia, Mexico, Singapore, Australia, Hong Kong, South Korea, and UAE. I was the on-site Engineer for some of those deployments. I have been part of the team that sets up equipment, tests the quality of data sources, assists with Factory Acceptance Tests, and is present when the system goes live.`,
        `Being part of the implementation team taught me to be resourceful and have better communication. There are unexpected needs that arise when your on-site and good communication and troubleshooting skills will help resolve them in time.`,
      ],
      score: 9,
      group: "Support",
      frequencyUse: "Daily",
      firstUsed: "2012",
      lastUsed: "2018",
    },
    {
      id: "techSupport",
      name: "Tech Support",
      description: [
        `I was part of the Tech Support Team for a company that developed Air Traffic Flow Management Systems. We supported operational systems, trial systems, and laboratory systems. The support team had to take support tickets through their lifecycle in the JIRA Service Desk. This included communication with the client while troubleshooting issues and working with System Engineers and Developers to get new requirements written and code changes made if needed. Our help desk was 24/7/365 which required a rotation of being on call.`,
        `The important thing that being on the support team taught me was the importance of having good system observability in the infrastructure and the applications. I keep the support specialist in mind when developing code so that log messages are properly formatted and contain useful information.`,
      ],
      score: 7,
      group: "Support",
      frequencyUse: "Daily",
      firstUsed: "2012",
      lastUsed: "Current",
    },
    {
      id: "projectManagement",
      name: "Project Management",
      description: [
        `I was the technical lead for several implementations of operational and trial Air Traffic Flow Management systems. For two of those projects in Colombia and Singapore, I became the project manager from 2016-2018. I took over managerial contractual requirements (e.g. progress reports) as well as resource and budget management for the projects.`,
        `I used the MS Project to track the project Earned Value Management and handle any changes made to the project schedule.`,
      ],
      score: 4,
      group: "Management",
      frequencyUse: "Daily",
      firstUsed: "2016",
      lastUsed: "2018",
    },
    {
      id: "training",
      name: "Training",
      description: [
        `My experience with training users has been in developing and conducting training modules for operational, trial, and simulation Air Traffic Flow Management Systems.`,
        `Notable in those experiences was training Air Traffic Controllers in Colombia and Mexico (both conducted in Spanish) and Singapore. I was heavily involved in the creation of the training material as well as setting up the system for training.`,
        `My experiences with training users help me write better documentation for my code today, if not for other users, then for future me.`,
      ],
      score: 8,
      group: "Education",
      frequencyUse: "Daily",
      firstUsed: "2014",
      lastUsed: "2018",
    },
    {
      id: "conceptDevelopment",
      name: "Concept Development",
      description: [
        `I worked in a Air Traffic Flow Management (ATFM) Company at a time when ATFM was beginning to be needed by Air Navigation Service Providers (ANSPs) (e.g. FAA). Many ANSPs didn't have a foundation of ATFM concepts and particularly how it would work for their country or region. I was involved the in development of the Concept of Operations in four countries: Colombia, Mexico, Australia, and Singapore. In Australia and Singapore, the concepts were brand new and solved the countries' particular use cases. In the case of Singapore, the concept was adopted for the South East Asia region. I participated as a technical advisor at about 20 Regional ATFM meetings and conferences.`,
      ],
      score: 9,
      group: "Education",
      frequencyUse: "Daily",
      firstUsed: "2014",
      lastUsed: "2018",
    },
    {
      id: "simulation",
      name: "Simulation",
      description: [
        `When I worked for a company that developed Air Traffic Flow Management (ATFM) Systems, we were also involved in developing operational concepts for countries or regions. This involved using the ATFM System as a laboratory for prototyping and conducting Human-in-the-Loop (HITL) experiments that would result in requirements for an operational system.`,
        `Much of the simulation was creating scenarios for ATFM simulations and working with the developers to add the prototype functionality.`,
      ],
      score: 9,
      group: "Education",
      frequencyUse: "Daily",
      firstUsed: "2014",
      lastUsed: "Current",
    },
    {
      id: "salesEngineering",
      name: "Sales Engineering",
      description: [
        `In 2009, I went, for the first time, to Bogota Colombia to sell an ATFM system. I didn't know it at the time, but I was acting as a Sales Engineer during that and subsequent trips. Every time we would visit a client we would also work on technical tools that would help support the sales, such as demo machines that can be quickly adapted to a new client site with minimal effort.`,
        `Sales efforts also included giving technical workshops that would educate potential clients about the need and challenges of Air Traffic Flow Management. I would prepare the materials, set up all the interactive demo equipment, and help conduct the workshop.`,
        `I also supported trade shows and conferences. I had to attend the booth, answer attendees’ questions, communicate any possible sales contacts, and assist in panel discussions.`,
      ],
      score: 7,
      group: "Sales",
      frequencyUse: "Monthly",
      firstUsed: "2009",
      lastUsed: "2018",
    },
  ],
}

const workExperienceData = [
  {
    organization: "Northwestern",
    organizationFullName: "Northwestern University",
    startDate: "Sept 2003",
    endDate: "Jun 2004",
    position: "Athletic Tutor",
    image: norwesternImg,
    description: [
      `While at Northwestern University, I was a tutor to athletes for the entirety of my Senior Year. This was a job that I took because another work-study job had fallen through but it was serendipitous that it did. I mostly taught Calculus I/II, Differential Equations, and some Physics to students of different sports. Teaching really tested how well I knew those subjects but also provided an interesting didactic challenge. I had to experiment with different ways of teaching a difficult topic. Having the perspective of a teacher made me a better student.`,
      `This experience also gave me a renewed appreciation for student-athletes. Juggling school and practice was not an easy task. I would watch some of my students compete on TV and all I could think about how much they busted their asses on and off the field.`,
    ],
  },
  {
    organization: "NASA",
    organizationFullName: "National Aeronautics and Space Administration",
    startDate: "Jun 2004",
    endDate: "Aug 2008",
    position: "Researcher",
    image: nasaImg,
    description: [
      `Performed research in conjunction with the FAA and JPDO to evaluate the benefits of introducing various NextGen concepts on NAS efficiency.`,
      `Performed research on the scale-free topological nature of the NAS network and used evolutionary algorithms to forecast growth and future structure.`,
      `Studied wake vortex dissipation using Monte Carlo methods for risk/safety assessment of the introduction of new procedures at airports throughout the USA.`,
      `Modeled autonomous personal air vehicle traffic in the greater New York area using agent-based modeling to study the coexistence of commercial and wider personal vehicle air traffic.`,
      `Evaluated proposals for SBIR and NRA projects that would bring most benefits to NextGen concepts.`,
    ],
  },
  {
    organization: "TNCC",
    organizationFullName: "Thomas Nelson Community College",
    startDate: "Aug 2006",
    endDate: "Jun 2008",
    position: "Adjunct Professor",
    image: tnccImg,
    description: [
      `I missed teaching after my experience as an athletic tutor at Northwestern so I took a part-time job while working at NASA Langley Research Center, teaching Algebra I/II and Trigonometry to adults continuing their education. The difference with this teaching experience was that I had to create lesson plans, homework, and tests. I taught some classes several times which gave me the opportunity to build on previous material and make the content more engaging.`,
      `I had fun teaching at TNCC! The subjects I taught were ones that were second nature to me because of my Mathematics training, but it forced me to review how I had learned each topic and come up with ways to make it more interesting. As a professor there, I was also able to take free classes. I couldn't pass up the opportunity to take visual arts classes.`,
    ],
  },
  {
    organization: "Metron Aviation",
    organizationFullName: "Metron Aviation",
    startDate: "Aug 2008",
    endDate: "Mar 2018",
    position: "Systems Engineer",
    image: metronImg,
    description: [
      `Served as Project Management and System Engineering support for full ATFM system implementation in Australia and technical lead for the full ATFM system implementation in Colombia. Principle duties included: client liaison for technical issues, requirements development, ensure product adherence to requirements through the agile process, FAT and SAT development and execution, CDM meeting coordination, end-user (ATCs, airlines, and airports) training, go-live execution, and continued operational support.`,
      `Served as Systems Engineering support and Lead for the following operational trial systems: Hong Kong, Singapore, Mexico, and the UAE. Primary duties were creating a scaled-down version of full ATFM system implementation.`,
      `Participated in the concept development of Long Range ATFM in Australia and Multi-Nodal regional ATFM of the SE Asia region in Singapore. Primary duties included: developing storyboards for stakeholder meetings of concept development, fast-time simulations for TMI parameter definition, algorithm development for the new concept, technical specification requirements for the new concept, and execution of Human-in-the-Loop experiments for concept validation.`,
      `Supported client and IATA in the SE Asia Multi-Nodal meetings that are defining how regional ATFM is to be implemented in the SE Asia Region.`,
      `Performed ATFM Measure algorithm development and validation. Created testbeds for slot-based ATFM Measures to develop slot allocation methods taking into account new requirements from clients prior to going into production.`,
      `Developed metrics and created data visualizations of demand predictability and ATFM Measure effectiveness.`,
      `Served as an operations research analyst at the ATC System Command Center in the USA. Duties included: Daily reports for air traffic managers, conducted ad-hoc reports through analysis or simulation, participated in operational teleconferences, and performed periodic checks of data quality in the reporting databases.`,
    ],
  },
  {
    organization: "Cavan Solutions",
    organizationFullName: "Cavan Solutions",
    startDate: "Mar 2018",
    endDate: "Current",
    position: "Systems Engineer",
    image: cavanImg,
    description: [
      `Developing Terminal Sequencing and Spacing (TSAS) adaptation in support of Time-Based Flow Management (TBFM) deployment with TSAS capabilities, including tools that aid in the creation and validation of the adaptation`,
      `Supports NextGen TSAS capability testing at the Federal Aviation Administration (FAA) William J. Hughes Technical Center (WJHTC) by providing input for scenario development and expert perspective on the adaptation`,
      `Developed Cavan Lab, a full data analytics platform, including a pipeline of TBFM source data, data storage, and a front-end interface for analysis and simulation, requiring the integration of many tools and scripts in various programming languages which had been developed by other Cavan team members to make the final platform function in a multi-container environment that is easily extensible and deployable`,
      `Developed the application programming interface (API) in Cavan Lab for data retrieval and front-end interface to analyze the data, utilizing Airflow for scheduling, Postgres and Elasticsearch for data storage, Docker for containerization, Django Rest Framework for APIs, Minio for unstructured data storage, and React for front-end`,
    ],
  },
]

export { learningData, skillsData, workExperienceData }
